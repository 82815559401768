<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-center"
    v-if="interview.textFile === '' && interview.audioFile === ''">
    <v-col cols="4">
      <div class="align-center text-center justify-center">
        <p class="title ml-0 mb-0">{{ interview.name }}</p>
        <v-img class="mx-auto mb-5" src="../../assets/no-data.svg" max-width="200"></v-img>
        <p class="display-1 font-weight-thin grey--text text--lighten-1">
          Aucun fichier chargé
        </p>
        <v-btn block class="mt-4" color="teal" outlined :to="`${$route.path}/upload/text`">
          <v-icon>mdi-plus</v-icon>Ajouter une transcription
        </v-btn>
        <v-btn block class="mt-4" color="indigo" outlined :to="`${$route.path}/upload/audio`">
          <v-icon>mdi-plus</v-icon> Ajouter un fichier Audio
        </v-btn>
      </div>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="3">
      <v-row>
        <v-col>
          <p class="display-1 font-weight-thin grey--text text--lighten-1">
            Actions
          </p>
          <v-card color="blue--text">
           <div class="d-flex flex-no-wrap justify-space-between">
             <div>
               <v-card-title class="text-h5">Analyse simple</v-card-title>
               <v-card-subtitle>Donnée Brutes</v-card-subtitle>
               <v-card-actions>
                 <v-btn :to="`${$route.path}/tool/basics`" color="blue"
                 class="ml-2 mt-1" outlined rounded>Voir</v-btn>
               </v-card-actions>
             </div>
             <v-avatar class="ma-1" size="125" tile>
              <v-img src="../../assets/analysis-blue.svg"></v-img>
             </v-avatar>
           </div>
         </v-card>
         <v-card class="mt-1" color="teal--text">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5">Analyse avancée</v-card-title>
              <v-card-subtitle>Syntaxe</v-card-subtitle>
              <v-card-actions>
                <v-btn :to="`${$route.path}/tool/syntax`" color="teal"
                class="ml-2 mt-1" outlined rounded>Voir</v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-1" size="125" tile>
             <v-img src="../../assets/analysis-teal.svg"></v-img>
            </v-avatar>
          </div>
        </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="display-1 font-weight-thin grey--text text--lighten-1">
            Autres informations
          </p>
          <v-list>
            <MediaItem title="Entretien audio" :uploaded="interview.audioFile != ''" media="audio"
            :link="`${$route.path}/upload/audio`" v-on:delete-audio="dropMedia('audio')"/>

            <MediaItem title="Fichier de transcription"
            :uploaded="interview.textFile != ''" media="text"
            :link="`${$route.path}/upload/text`" v-on:delete-text="dropMedia('text')"/>
          </v-list>
          <v-row>
            <v-col>
              <v-skeleton-loader v-show="!content.length" class="mx-auto" type="table-tbody">
              </v-skeleton-loader>

              <v-simple-table v-if="content" dense class="mb-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Donnée
                      </th>
                      <th class="text-left">
                        Valeur
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                     <td>Tours de parole</td><td>{{ content.length }}</td>
                   </tr>
                   <tr>
                     <td>Durée</td><td>{{ content[content.length-1].end | timify }}</td>
                    </tr>
                    <tr>
                      <td>Participants</td>
                      <td>
                        {{ content.map(x=>(x.participant))
                          .sort()
                          .filter((x, i, a) => !i || x != a[i-1])
                          .toString()
                          .replaceAll('[', '')}}
                      </td>
                     </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
        </v-row>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn class="float-right" small icon link :to="`${$route.path}/update`" v-on="on">
                <v-icon small>mdi-pen</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <p class="title ml-0 mb-0">Commentaires</p>
            <v-divider></v-divider>
            <v-row>
              <v-col>
              <p>{{ interview.comment || 'Pas de commentaire' }}</p>
            </v-col>
          </v-row>
          <div v-if="interview.audioFile">
            <p class="title ml-0 mb-0">Audio</p>
          <v-divider></v-divider>
          <audio controls preload="auto" ref="audio_player"
            :src="`/${interview.audioFile}`">
          </audio>
          <!-- <vuetify-audio ref="vuetifyplayer"
            :file="`http://localhost:8000/${interview.audioFile}`"
            color="primary" downloadable>
          </vuetify-audio> -->
        </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="align-center">
        <p class="display-1 font-weight-thin grey--text text--lighten-1">
          Contenu de l'entretien
        </p>
        <v-skeleton-loader v-show="!content.length" class="mx-auto" type="table">
        </v-skeleton-loader>

        <v-simple-table dense v-show="content.length" class="mb-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Début</th>
                <th class="text-center">Fin</th>
                <th class="text-center">Participant</th>
                <th class="text-left">Tour de parole</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in content"  :key="i">
                <td>
                  <v-btn v-show="interview.audioFile"
                  class="mr-1" icon outlined x-small @click="play(item.start, item.end)">
                  <v-icon x-small>mdi-play</v-icon>
                </v-btn>
                <code>{{ item.start | timify }}</code></td>
                <td><code>{{ item.end | timify }}</code></td>
                <td><v-chip small>{{ item.participant }}</v-chip></td>
                <td>{{ item.speech }}</td>
             </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import MediaItem from './MediaItem.vue';

export default {
  name: 'InterviewDashboard',
  components: { MediaItem },
  mixins: [MixinPatients, MixinInterviews],
  props: {
    interviewId: String,
    patientId: String,
  },
  data: () => ({
    interview: Object(),
    content: [],
    playing: false,
  }),

  async mounted() {
    await this.updateInterviewInfo();
    // const myAudio = this.$refs.audio_player;
    // myAudio.addEventListener('seeked', () => {
    //   myAudio.play();
    // }, false);
  },

  methods: {
    play(start, end) {
      const myAudio = this.$refs.audio_player; // this.$refs.player;
      myAudio.currentTime = start / 1000;
      myAudio.int = setInterval(() => {
        if (myAudio.currentTime > end / 1000) {
          myAudio.pause();
          clearInterval(myAudio.int);
        }
      }, 100);
    },
    async updateInterviewInfo() {
      this.interview = await this.getInterviewInfo(this.patientId, this.interviewId);
      const data = await this.getAnalysisResult(this.patientId, this.interviewId, ['speech']);
      this.content = data.speech;
      this.interview.date = this.convertDate(this.interview.date);
    },

    async dropMedia(mediaType) {
      await this.deleteMedia(this.interviewId, this.patientId, mediaType);
      this.updateInterviewInfo();
    },

    async comment() {
      await this.updateInterview();
    },
  },
};
</script>
