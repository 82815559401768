<template>
  <div>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
        <v-list-item-subtitle>
          <v-icon small :color="uploaded ? 'teal':'red'"
            v-text="uploaded ? 'mdi-checkbox-marked-circle' : 'mdi-alert-circle'">
          </v-icon>
          {{ uploaded ? 'Enregistré' : 'Aucun' }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn-toggle>
          <v-btn v-if="!uploaded" outlined :to="link">
            <v-icon color="teal">mdi-upload</v-icon>
          </v-btn>
          <v-btn v-if="uploaded" outlined @click="dialog = true">
            <v-icon color="red darken-2">mdi-delete</v-icon>
          </v-btn>
          </v-btn-toggle>
      </v-list-item-action>
    </v-list-item>
    <v-dialog v-model="dialog" persistent max-width="35%">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <v-card-text>
          <p>Le fichier {{ title }} va être définitivement supprimé.</p>
          <p>Êtes-vous sûr de vouloir continuer ?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog= false;">Annuler</v-btn>
          <v-btn color="red" outlined @click="deleteMediaFile();">Supprimer le fichier</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MediaItem',
  props: {
    uploaded: Boolean,
    media: String,
    title: String,
    link: String,
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    deleteMediaFile() {
      this.$emit(`delete-${this.media}`);
      this.dialog = false;
    },
  },
};
</script>
